import axios from "axios";

export const getBudget = () =>
  axios.get(`${process.env.GATSBY_API_ROOT}/pmo-collection/v1/budgets`);

export const getBudgetDetail = (id) =>
  axios.get(`${process.env.GATSBY_API_ROOT}/pmo-collection/v1/budgets/${id}`);

export const addBudget = (params) =>
  axios.post(`${process.env.GATSBY_API_ROOT}/pmo-collection/v1/budgets`, params);

export const patchBudget = (id, params) =>
  axios.patch(`${process.env.GATSBY_API_ROOT}/pmo-collection/v1/budgets/${id}`, params);

export const deleteBudget = (id, params) =>
  axios.delete(`${process.env.GATSBY_API_ROOT}/pmo-collection/v1/budgets/${id}`, { data: params });
