import React from "react";
import BudgetDashboard from "../../components/Budget";
import Seo from "../../components/seo";

const BudgetPage = (props) => (
  <>
    <Seo title="Budget Dashboard" />
    <BudgetDashboard {...props} />
  </>
);

export default BudgetPage;
