import { adminContextStore } from "../context/store/createStore";
import { SET_BUDGET_LIST } from "../context/actions/budget";
import { getBudget } from "../httpRequests/budget";
// exlint-disable-next-line
export default async () => {
  const { dispatch } = adminContextStore;

  try {
    dispatch({
      type: SET_BUDGET_LIST,
      payload: {
        loading: true,
      },
    });

    const response = await getBudget();

    dispatch({
      type: SET_BUDGET_LIST,
      payload: {
        loading: false,
        budget: response.data,
      },
    });
  } catch (error) {
    dispatch({
      type: SET_BUDGET_LIST,
      payload: {
        loading: false,
      },
    });
  }
};
