const EFFORT_TEAM = [
  "PM",
  "PO",
  "Local Dev - ECI",
  "Local Dev - Identity",
  "Local Dev - Mobile",
  "Local Dev - Store",
  "Local Dev - Sharepoint",
  "Local Dev - RPA",
  "QA",
  "Vendor",
  "Solution Architect",
];

export default EFFORT_TEAM;
