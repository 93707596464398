import React from "react";
import { Link } from "gatsby";

const Breadcrumb = ({ page, link, detail }) => (
  <div className="flex links">
    <Link to="/">Home</Link>
    <span>/</span>
    {detail && (
      <>
        <Link to={`/projectList`}>Project Lists</Link>
        <span>/</span>
      </>
    )}

    <Link to={`/${link}`}>{page}</Link>
  </div>
);

export default Breadcrumb;
