import React, { useState, useEffect, useCallback } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import { ModalWrapper } from "../styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  addBudget,
  patchBudget,
  getBudgetDetail,
} from "../../httpRequests/budget";
import { SET_SNACKBAR } from "../../context/actions/snackbar";
import { useAdminState, useAdminDispatch } from "../../context/AdminContext";
import BudgetInfo from "./Form";
import UUID from "../../utils/uuid";

const AddEditBudget = ({ onClose, action, refetch, setRefetch, id }) => {
  const dispatch = useAdminDispatch();
  const [data, setData] = useState({});
  const [budget, setBudget] = useState({});
  const [loading, setLoading] = useState(false);
  const [effort, setEffort] = useState([]);
  const { login: { user: { profile } = {} } = {} } = useAdminState();

  useEffect(() => {
    const fetchBudgetDetail = async () => {
      if (id) {
        setLoading(true);
        const res = await getBudgetDetail(id);
        if (res.status === 200) {
          setBudget(res.data);
          setData(res.data);
        }
        setLoading(false);
      }
    };
    fetchBudgetDetail();
  }, [id]);

  const dispatchSnackbar = useCallback(
    (success, message) => {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          open: true,
          message,
          severity: success ? "success" : "error",
        },
      });
    },
    [dispatch]
  );

  const validation = useCallback(() => {
    if (!data.projectMainName) {
      dispatchSnackbar(false, "Please fill in all required fields");
      return false;
    }
    return true;
  }, [data, dispatchSnackbar]);

  const onSubmit = async () => {
    if (!validation()) return;

    const budgetData = { ...data, effort };

    try {
      let response;
      if (action === "add") {
        budgetData.createdBy = profile?.given_name;
        budgetData.budgetId = UUID();
        response = await addBudget(budgetData);
        dispatchSnackbar(
          response.status === 200,
          response.status === 200
            ? "Budget Added Successfully"
            : response.error.description
        );
      } else {
        budgetData.updatedBy = profile?.given_name;
        response = await patchBudget(id, budgetData);
        dispatchSnackbar(
          response.status === 200,
          response.status === 200
            ? "Budget Updated Successfully"
            : response.error.description
        );
      }
      if (response.status === 200) {
        setRefetch(!refetch);
        onClose();
      }
    } catch (error) {
      dispatchSnackbar(false, error.message);
    }
  };

  const onDataChange = useCallback((name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  }, []);

  return (
    <ModalWrapper>
      <div className="editing-project">
        <div className="flex">
          <DialogTitle className="title">
            {id ? "Editing Budget" : "Add New Budget"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <DialogContent>
          <div className="form-wrapper">
            {!loading && (
              <BudgetInfo
                onDataChange={onDataChange}
                budget={budget}
                setEffort={setEffort}
                id={id}
                onClose={onClose}
                refetch={refetch}
                setRefetch={setRefetch}
              />
            )}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className="modal-actions">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className="submit"
            color="primary"
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </div>
    </ModalWrapper>
  );
};

export default AddEditBudget;
