import React, { useEffect, useState, useMemo } from "react";
import { Container, Dialog, Fab } from "@mui/material";
import Breadcrumb from "../../shared/Breadcrumbs";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import numberWithCommas from "../../utils/numberWithCommas";
import { makeStyles } from "@material-ui/core/styles";
import getBudget from "../../services/getBudget";
import { useAdminState } from "../../context/AdminContext";
import Loading from "../../shared/Loading";
import { PlusIcon } from "@heroicons/react/24/outline";
import AddEditBudget from "../AddEditBudget";

const useStyles = makeStyles((theme) => ({
  add: {
    width: 24,
  },
}));

const BudgetDashboard = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { loading, budget } = useAdminState().budget;
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [editID, setEditID] = useState(null);

  useEffect(() => {
    getBudget();
  }, [refetch]);

  useEffect(() => {
    setData(budget || []);
  }, [budget]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "projectMainName",
        header: "Project",
      },

      {
        accessorKey: "icProjectCode",
        header: "Project Code",
      },

      {
        accessorKey: "amount",
        header: "Amount (THB)",
        Cell: ({ cell }) => {
          const converted = cell.getValue() || 0;
          return <span>{numberWithCommas(converted.toString())}</span>;
        },
      },
      {
        accessorKey: "note",
        header: "Note",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    initialState: {
      pagination: { pageSize: 25, pageIndex: 0 },
    },
    muiTableBodyRowProps: ({ row, table }) => ({
      onClick: (e) => {
        onClickRow(e, table.getRow(row.id).original.budgetId);
      },
      sx: {
        cursor: "pointer",
      },
    }),
  });

  const onClickRow = async (_, id) => {
    setEditID(id);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setEditID(null);
  };

  return (
    <Container className="dashboard">
      <div className="page-header">
        <h2>Budget Dashboard</h2>
        <div className="flex">
          <Breadcrumb page="Budget Dashboard" link="budget" />
        </div>
      </div>

      {typeof window !== "undefined" &&
        localStorage.getItem("role") === "admin" && (
          <Fab
            color="primary"
            aria-label="add"
            className="add-project"
            onClick={() => setOpen(true)}
          >
            <PlusIcon className={classes.add} />
          </Fab>
        )}

      {loading ? (
        <Loading />
      ) : (
        <div className="budget-dashboard">
          {data && data?.length > 0 && (
            <div className="project-table-wrapper">
              <MaterialReactTable table={table} />
            </div>
          )}
        </div>
      )}

      <Dialog maxWidth="lg" onClose={onClose} open={open}>
        <AddEditBudget
          onClose={onClose}
          action={editID ? "edit" : "add"}
          id={editID}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      </Dialog>
    </Container>
  );
};
export default BudgetDashboard;
